import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import { cardsData } from './ServiceData';
import Footer from './Footer';
import backgroundImage from "../images/portfolio.webp";
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger'; // Import ScrollTrigger

gsap.registerPlugin(ScrollTrigger); // Register ScrollTrigger with GSAP

const Card = React.forwardRef(({ id, bgImage, title, description }, ref) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const navigate = useNavigate();

  const openModal = () => {
    navigate(`/Contact`);
  };

  return (
    <div ref={ref} key={id} className="max-w-md rounded shadow-lg m-4">
      <div
        className="relative md:w-96 h-96 rounded-lg dark:bg-black bg-cover bg-center duration-500 ease-in-out filter grayscale"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => openModal(bgImage)}
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className="px-6 py-10 text-center">
          <div className="font-bold text-xl font-spaceGrotesk mb-2 text-white text-center">
            {title}
          </div>
        </div>
        <div className="absolute top-0 left-0 w-full h-full transition-opacity duration-300 ease-in-out bg-opacity-50 hover:bg-opacity-100 flex justify-end items-end p-10">
          <p className={`bg-gray-500/30 text-xs md:text-sm font-spaceGrotesk text-white p-10 transition-transform ease-in-out duration-${isHovered ? '3000' : '6000'} ${isHovered ? 'scale-100' : 'scale-90'}`}>
            {description}
          </p>
        </div>
      </div>
    </div>
  );
});

const Services = () => {
  const cardRefs = useRef([]);

  useEffect(() => {
    gsap.fromTo('.heading', {
      y: -100,
      opacity: 0,
    }, {
      y: 0,
      opacity: 1,
      duration: 2,
      delay:1
    });

    cardRefs.current.forEach((ref, index) => {
      gsap.fromTo(ref,
        {
          y: -50,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          ease: "power1.inOut",
          duration: 1.5,
          stagger: 0.5,
          delay: index * 0.2,
        }
      );
    });
  }, []);

  return (
    <>
      <Header />
      <div className="min-h-screen bg-cover p-10 bg-center flex flex-col items-center justify-center" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <h1 className="heading text-3xl font-bold text-white font-spaceGrotesk mt-10 mb-6 border-b border-red-500 align-middle">Services</h1>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 m-0 md:m-10">
          {cardsData.map((card, index) => (
            <Card ref={(el) => (cardRefs.current[index] = el)} key={index} id={card.id} bgImage={card.bgImage} title={card.title} description={card.description} />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Services;
