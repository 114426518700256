// Importing images
import WeddingImage from '../images/services/1.webp';
import FashionImage from '../images/services/2.webp';
import PortraitImage from '../images/services/3.webp';
import EventImage from '../images/services/4.webp';
import ProductImage from '../images/services/5.webp';
import FieldImage from '../images/services/6.webp';

export const cardsData = [
  {
    id: 1,
    bgImage: WeddingImage,
    title: "Wedding Photography",
    description: "Our wedding photography captures the essence of your special day with timeless elegance..."
  },
  {
    id: 2,
    bgImage: FashionImage,
    title: "Fashion Photography",
    description: "Elevate your brand with stunning fashion photography that showcases your style and creativity..."
  },
  {
    id: 3,
    bgImage: PortraitImage,
    title: "Portrait Photography",
    description: "Capture the essence of yourself or your loved ones with our professional portrait photography services..."
  },
  {
    id: 4,
    bgImage: EventImage,
    title: "Event Photography",
    description: "Preserve the memories of your special events with our event photography services..."
  },
  {
    id: 5,
    bgImage: ProductImage,
    title: "Product Photography",
    description: "Showcase your products in the best light with our product photography services..."
  },
  {
    id: 6,
    bgImage: FieldImage,
    title: "Field Photography",
    description: "We specialize in capturing captivating moments and breathtaking scenery through our field photography services."
  }
];
