import React, { useEffect, useState } from 'react';
import { FaPhone, FaEnvelope } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import Header from "./Header";
import Footer from "./Footer";
import backgroundImage from "../images/portfolio.webp"
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

export default function ContactSection({ showHeader = true, showFooter = true }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    isFormValid: false,
    errorMessage: '',
  });
  

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;

    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Regular expression for phone number validation
    const phoneRegex = /^\d{11}$/;

    let isFormValid = true;
    let errorMessage = '';

    if (inputValue.trim() === '') {
      isFormValid = false;
      errorMessage = 'Please fill out all fields.';
    } else if (name === 'email' && !emailRegex.test(inputValue)) {
      isFormValid = false;
      errorMessage = 'Invalid email address';
    } else if (name === 'phone' && !phoneRegex.test(inputValue)) {
      isFormValid = false;
      errorMessage = 'Invalid phone number (11 digits only)';
    }

    // Update formData state
    setFormData({
      ...formData,
      [name]: inputValue,
      isFormValid: isFormValid,
      errorMessage: '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let isFormValid = true;
    let errorMessage = '';
    if (formData.name.trim() === '' || formData.email.trim() === '' || formData.phone.trim() === '' || formData.message.trim() === '') {
      isFormValid = false;
      errorMessage = 'Please fill out all fields.';
    } else if (!formData.isFormValid) {
      isFormValid = false;
      errorMessage = 'Please fill out all fields correctly.';
    }
    setFormData({
      ...formData,
      isFormValid: isFormValid,
      errorMessage: errorMessage,
    });

    if (isFormValid) {
      setIsSubmitting(true);
      setSuccessMessage('');
      try {
        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('phone', formData.phone);
        formDataToSend.append('message', formData.message);
        formDataToSend.append('access_key', 'ba90a67b-3e92-4295-b461-52d2539b4b74');

        const response = await fetch('https://api.web3forms.com/submit', {
          method: 'POST',
          body: formDataToSend,
        });

        const data = await response.json();

        if (data.success) {
          setSuccessMessage('Message sent successfully!');
          setFormData({
            name: '',
            email: '',
            phone: '',
            message: '',
            isFormValid: false,
            errorMessage: '',
          });
        } else {
          throw new Error(data.message);
        }
      } catch (error) {
        setFormData({
          ...formData,
          errorMessage: error.message,
        });
      } finally {
        setIsSubmitting(false);
      }
    }
  };
  useEffect(()=>{
    gsap.fromTo('.txt', {
      opacity:0,
      x: 1000,
      ease: 'power4.out',
     
    },
  {
    opacity:1,
    x: 0,
    scrollTrigger:{
      trigger: '.txt',
      start: 'top 100%',
      end: 'top 20%',
      toggleActions: 'play none none none',
    },
    duration:2
  });
  gsap.fromTo('.leftCard', {
    opacity:0,
    x: -500,
    ease: 'power4.out',
  },
{
  opacity:1,
  x: 0,
  scrollTrigger:{
    trigger: '.leftCard',
    start: 'top 100%',
    end: 'top 20%',
    toggleActions: 'play none none none',
  },
  duration:2
});
  gsap.fromTo('.rightCard', {
    opacity:0,
    y: 500,
    ease: 'power4.out',
  },
{
  opacity:1,
  y: 0,
  scrollTrigger:{
    trigger: '.rightCard',
    start: 'top 100%',
    end: 'top 20%',
    toggleActions: 'play none none none',
  },
  duration:2
});
  },[])

  return (
    <>
      {showHeader && <Header />}
      <div className="min-h-screen bg-cover p-10 bg-center flex flex-col items-center justify-center" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className='container my-24 mx-auto md:px-6'>
          <div className='bg-zinc px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] backdrop-blur-[30px] dark:bg-[hsla(0,0%,5%,0.7)] dark:shadow-black/20 p-8 text-center rounded-lg'>
            <h2 className='txt text-2xl font-bold font-spaceGrotesk mb-4 text-white underline decoration-red-500'>MAIN OFFICE</h2>
            <p className='txt mb-2 text-white font-spaceGrotesk dark:text-gray-400'> <IoLocationOutline className=' text-red-600' /> 105, Building, 3C 7th E St, DHA Phase 5 Zamzama Commercial Area Defence V Defence, Karachi, Karachi City, Sindh 75500, Pakistan</p>
           
            <p className='txt mb-2 text-white dark:text-gray-400 font-spaceGrotesk '> <FaPhone className=' text-red-600' /> +923362645456</p>
            <p className='txt mb-2 text-white dark:text-gray-400 font-spaceGrotesk'><FaEnvelope className=' text-red-600' />info@studioredcam.com</p>
          </div>
          <section className='text-center'>
            <div className='py-12 lg:mr-20 md:px-12'>
              <div className='container mx-auto xl:px-32'>
                <div className='grid lg:grid-cols-2 md:space-x-10'>
                  <div className='leftCard mb-12 md:mt-12 lg:mt-0 lg:mb-0'>
                    <div className='relative block rounded-lg text-white zinc px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] backdrop-blur-[30px] dark:bg-[hsla(0,0%,5%,0.7)] dark:shadow-black/20 md:px-12'>
                      <h2 className='mb-12 text-3xl font-bold underline decoration-red-500'>CONTACT US</h2>
                      <form onSubmit={handleSubmit}>
                        <div className='relative mb-6' data-te-input-wrapper-init>
                          <input
                            type='text'
                            name='name'
                            value={formData.name}
                            onChange={handleChange}
                            className='peer block min-h-[auto] w-full rounded border border-blue-500 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0'
                            id='exampleInput90'
                            placeholder='Name'
                          />
                          <label
                            className={`pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out ${
                              formData.name && 'opacity-0'
                            } peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary`}
                            htmlFor='exampleInput90'
                          >
                            Your Name
                          </label>
                        </div>
                        <div className='relative mb-6' data-te-input-wrapper-init>
                          <input
                            type='email'
                            name='email'
                            value={formData.email}
                            onChange={handleChange}
                            className='peer block min-h-[auto] w-full rounded border border-blue-500 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0'
                            id='exampleInput91'
                            placeholder='Email address'
                          />
                          <label
                            className={`pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out ${
                              formData.email && 'opacity-0'
                            } peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary`}
                            htmlFor='exampleInput91'
                          >
                            Email address
                          </label>
                        </div>
                        <div className='relative mb-6' data-te-input-wrapper-init>
                          <input
                            type='text'
                            name='phone'
                            value={formData.phone}
                            onChange={handleChange}
                            className='peer block min-h-[auto] w-full rounded border border-blue-500 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0'
                            id='exampleInput92'
                            placeholder='Phone'
                          />
                          <label
                            className={`pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out ${
                              formData.phone && 'opacity-0'
                            } peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary`}
                            htmlFor='exampleInput92'
                          >
                            Phone
                          </label>
                        </div>
                        <div className='relative mb-6' data-te-input-wrapper-init>
                          <textarea
                            name='message'
                            value={formData.message}
                            onChange={handleChange}
                            className='peer block min-h-[auto] w-full rounded border border-blue-500 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0'
                            id='exampleFormControlTextarea1'
                            placeholder='Your message'
                          ></textarea>
                          <label
                            className={`pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out ${
                              formData.message && 'opacity-0'
                            } peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary`}
                            htmlFor='exampleFormControlTextarea1'
                          >
                            Your message
                          </label>
                        </div>
                        {formData.errorMessage && <div className="text-red-500 mb-4">{formData.errorMessage}</div>}
                        {successMessage && <div className="text-green-500 mb-4">{successMessage}</div>}
                        <button
                          type='submit'
                          className='w-full rounded bg-red-600 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg'>
                          {isSubmitting ? 'Sending...' : 'Send'}
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className='rightCard mb-12 lg:mb-0'>
                    <div className='relative h-[700px] rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.3),0_10px_20px_-2px_rgba(0,0,0,0.3)] dark:shadow-black/20'>
                      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14485.355620849501!2d67.0406131!3d24.8180812!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33d7ff2918ddd%3A0x7ca0fa7dcae8e686!2sRedCam%20Studio!5e0!3m2!1sen!2s!4v1721333679030!5m2!1sen!2s" width="600" height="450"  allowfullscreen="" 
                      className='left-0 top-0 h-full w-full absolute rounded-lg' loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      {showFooter && <Footer />}
    </>
  );
}
