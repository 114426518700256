// Import React and necessary components
import React from 'react';
import Slider from "react-slick";
import { IoIosArrowForward,IoIosArrowBack } from "react-icons/io";

// Import images
import image_1 from "../../images/slider/1.webp";
import image_2 from "../../images/slider/2.webp";
import image_3 from "../../images/slider/3.webp";
import image_4 from "../../images/slider/4.webp";
import image_5 from "../../images/slider/5.webp";

// Define image list
const ImageList = [
    {
        id: 1,
        img: image_1,
    },
    {
        id: 2,
        img: image_2,
    },
    {
        id: 3,
        img: image_3,
    },
    {
        id: 4,
        img: image_4,
    },
    {
        id: 5,
        img: image_5,
    },
];


const Hero = () => {
const CustomPrevArrow = ({ onClick }) => {
    return (
        <div
            className="absolute top-1/2 left-0 transform -translate-y-1/2 w-10 h-10 bg-black bg-opacity-50 text-white flex justify-center items-center cursor-pointer z-10"
            onClick={onClick}
        >
            <IoIosArrowBack />
        </div>
    );
};
const CustomNextArrow = ({ onClick }) => {
    return (
        <div
            className="absolute top-1/2 right-0 transform -translate-y-1/2 w-10 h-10 bg-black bg-opacity-50 text-white flex justify-center items-center cursor-pointer z-10"
            onClick={onClick}
        >
           <IoIosArrowForward />
        </div>
    );
};
    // Slider settings
    var settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 400,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        cssEase: "ease-in-out",
        pauseonHover: false,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />
    };

    return (
        <div className='relative overflow-hidden'>
            <div className='lg:h-screen'>
                <Slider {...settings}>
                    {ImageList.map((data) => (
                        <div key={data.id} className='relative flex'>
                            <img
                                src={data.img}
                                className='w-full h-full object-cover mt-10'
                                alt=''
                            />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default Hero;
