import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import cardsData from './portfilio';
import backgroundImage from "../images/portfolio.webp"
import withSpinner from './withSpinner';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

const Card = React.forwardRef(({ id, bgImage, title, description }, ref) => {
  const navigate = useNavigate();

  const openModal = () => {
    navigate(`/PortfolioDetail/${id}`);
  };
  return (
    <>
      <div ref={ref} key={bgImage.id} className="max-w-md rounded shadow-lg m-4">
        <div
          className="relative md:w-96 h-96 rounded-lg dark:bg-black bg-cover bg-center hover:scale-[110%]  duration-500 ease-in-out filter grayscale"
          onClick={() => openModal(bgImage)}
          style={{
            backgroundImage: `url(${bgImage})`
          }}
        >
          <div className="px-6 py-4 ">
            <div className="font-bold font-spaceGrotesk text-xl mb-2 text-white text-center">
              {title}
            </div>
          </div>
          <div className="absolute top-0 left-0 w-full h-full  transition-opacity duration-300 ease-in-out bg-opacity-50 hover:bg-opacity-100 flex justify-end items-end p-10 ">
            <p className="bg-gray-500/30 font-spaceGrotesk text-white  text-sm hover:opacity-100 transition-opacity duration-500 ease-in-out ">
              {description}
            </p>
          </div>
        </div>
      </div>
    </>
  );
});
const Gallery = () => {
  const cardRefs = useRef([]);

  useEffect(() => {
    gsap.fromTo('.heading', {
      y: -100,
      opacity: 0,
    }, {
      y: 0,
      opacity: 1,
      duration: 2,
      delay:1
    });
    cardRefs.current.forEach((ref, index) => {
      gsap.fromTo(ref,
        {
          x: -50,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
          ease: "power1.inOut",
          duration: 1.5,
          stagger: 0.5,
          scrollTrigger: {
            trigger: ref,
            start: "top 85%",
            end: "top 20%",
            toggleActions: "play none none none",
          },
          delay: index * 0.2,
        }
      );
    });
  }, []);
  return (
    <>
      <div className="min-h-screen bg-cover p-10 bg-center flex flex-col items-center justify-center" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 m-0 md:m-10">
          {cardsData.slice(0,3).map((card, index) => (
            <Card ref={(el) => (cardRefs.current[index] = el)} key={index} id={card.id} bgImage={card.bgImage} title={card.title} description={card.description} />
          ))}
        </div>
      </div>
    </>
  );
};

export default withSpinner(Gallery);
