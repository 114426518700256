import React from 'react';
import { FaWhatsapp, FaInstagram } from 'react-icons/fa'; 
const SocialButtons = () => {
  const handleWhatsAppClick = () => {
    window.open('https://wa.me/+923362645456', '_blank');
  };

  const handleInstagramClick = () => {
    window.open('https://www.instagram.com/redcam.studio/', '_blank');
  };

  return (
    <div>
      <button
        onClick={handleWhatsAppClick}
        className="fixed bottom-20 right-8 bg-green-500 text-white rounded-full p-4 shadow-lg hover:scale-110 hover:bg-green-600 focus:outline-none"
      >
        <FaWhatsapp className="w-6 h-6" />
      </button>
      <button
        onClick={handleInstagramClick}
        className="fixed bottom-20 left-8 bg-gradient-to-r from-purple-500 to-orange-400 text-white rounded-full p-4 shadow-lg hover:scale-110 focus:outline-none"
        style={{ zIndex: 1000 }}
      >
        <FaInstagram className="w-6 h-6" />
      </button>
    </div>
  );
};

export default SocialButtons;
