import FashionBG from '../images/Gallery/Fashion.webp';
import WeddingBG from '../images/Gallery/wedding.webp';
import EventBG from '../images/Gallery/event.webp';
import ProductBG from '../images/Gallery/Product.webp';
import FieldBG from '../images/Gallery/Field.webp';

import Fashion1 from '../images/Fashion/1.webp';
import Fashion2 from '../images/Fashion/2.webp';
import Fashion3 from '../images/Fashion/3.webp';
import Fashion4 from '../images/Fashion/4.webp';
import Fashion5 from '../images/Fashion/5.webp';
import Fashion6 from '../images/Fashion/6.webp';
import Fashion7 from '../images/Fashion/7.webp';
import Fashion8 from '../images/Fashion/8.webp';
import Fashion9 from '../images/Fashion/9.webp';
import Fashion10 from '../images/Fashion/10.webp';
import Fashion11 from '../images/Fashion/11.webp';
import Fashion12 from '../images/Fashion/12.webp';

import wedding1 from '../images/wedding/1.webp';
import wedding2 from '../images/wedding/2.webp';
import wedding3 from '../images/wedding/3.webp';
import wedding4 from '../images/wedding/4.webp';
import wedding5 from '../images/wedding/5.webp';
import wedding6 from '../images/wedding/6.webp';
import wedding7 from '../images/wedding/7.webp';
import wedding8 from '../images/wedding/8.webp';
import wedding9 from '../images/wedding/9.webp';

import Event1 from '../images/Event/1.webp';
import Event2 from '../images/Event/2.webp';
import Event3 from '../images/Event/3.webp';
import Event4 from '../images/Event/4.webp';
import Event5 from '../images/Event/5.webp';
import Event6 from '../images/Event/6.webp';
import Event7 from '../images/Event/7.webp';
import Event8 from '../images/Event/8.webp';
import Event9 from '../images/Event/9.webp';
import Event10 from '../images/Event/10.webp';
import Event11 from '../images/Event/11.webp';
import Event12 from '../images/Event/12.webp';

import Field1 from '../images/Field/1.webp';
import Field2 from '../images/Field/2.webp';
import Field3 from '../images/Field/3.webp';
import Field4 from '../images/Field/4.webp';
import Field5 from '../images/Field/5.webp';
import Field6 from '../images/Field/6.webp';
import Field7 from '../images/Field/7.webp';
import Field8 from '../images/Field/8.webp';

import product1 from '../images/product/1.webp';
import product2 from '../images/product/2.webp';
import product3 from '../images/product/3.webp';
import product4 from '../images/product/4.webp';
import product5 from '../images/product/5.webp';
import product6 from '../images/product/6.webp';
import product7 from '../images/product/7.webp';
import product8 from '../images/product/8.webp';
import product9 from '../images/product/9.webp';
import product10 from '../images/product/10.webp';
import product11 from '../images/product/11.webp';

const cardsData = [
  {
    id: 1,
    bgImage: FashionBG,
    title: "Fashion Photography",
    ImageDetail: {
      image1: Fashion1,
      image2: Fashion2,
      image3: Fashion3,
      image4: Fashion4,
      image5: Fashion5,
      image6: Fashion6,
      image7: Fashion7,
      image8: Fashion8,
      image9: Fashion9,
      image10: Fashion10,
      image11: Fashion11,
      image12: Fashion12,
    },
    description: "Elevate your brand with stunning fashion photography that showcases your style and creativity...",
  },
  {
    id: 2,
    bgImage: WeddingBG,
    title: "Wedding Photography",
    ImageDetail: {
      image1: wedding1,
      image2: wedding2,
      image3: wedding3,
      image4: wedding4,
      image5: wedding5,
      image6: wedding6,
      image7: wedding7,
      image8: wedding8,
      image9: wedding9,
    },
    description: "Our wedding photography captures the essence of your special day with timeless elegance...",
  },
  {
    id: 3,
    bgImage: EventBG,
    title: "Event Photography",
    ImageDetail: {
      image1: Event1,
      image2: Event2,
      image3: Event3,
      image4: Event4,
      image5: Event5,
      image6: Event6,
      image7: Event7,
      image8: Event8,
      image9: Event9,
      image10: Event10,
      image11: Event11,
      image12: Event12,
    },
    description: "Preserve the memories of your special events with our event photography services...",
  },
  {
    id: 4,
    bgImage: "https://images.pexels.com/photos/2681751/pexels-photo-2681751.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    title: "Portrait Photography",
    ImageDetail: {
      image1: "https://images.pexels.com/photos/4667880/pexels-photo-4667880.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      image2: "https://images.pexels.com/photos/35537/child-children-girl-happy.webp?auto=compress&cs=tinysrgb&w=800",
      image3: "https://images.pexels.com/photos/19991875/pexels-photo-19991875/free-photo-of-sight-mate.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    description: "Capture the essence of yourself or your loved ones with our professional portrait photography services...",
  },
  {
    id: 5,
    bgImage: ProductBG,
    title: "Product Photography",
    ImageDetail: {
      image1: product1,
      image2: product2,
      image3: product3,
      image4: product4,
      image5: product5,
      image6: product6,
      image7: product7,
      image8: product8,
      image9: product9,
      image10: product10,
      image11: product11,
    },
    description: "Showcase your products in the best light with our product photography services...",
  },
  {
    id: 6,
    bgImage: FieldBG,
    title: "Field Photography",
    ImageDetail: {
      image1: Field1,
      image2: Field2,
      image3: Field3,
      image4: Field4,
      image5: Field5,
      image6: Field6,
      image7: Field7,
      image8: Field8,
    },
    description: "Our headshot photography services capture your professionalism and personality in a single image...",
  },
];

export default cardsData;
