import { FaEnvelope, FaFacebookF, FaTwitter, FaYoutube, FaInstagram, FaLinkedinIn, FaTiktok } from 'react-icons/fa';

function Footer() {
  return (
    <footer className=" bg-black text-white font-bold ">
      <div className="subfooter py-6">
        <div className="container mx-auto">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="mb-3 md:mb-0 md:w-1/2 text-center md:text-left font-spaceGrotesk ">
              &copy; 2024 Redcam Studio. All rights reserved.
            </div>
            <div className="md:w-1/2 text-center md:text-right flex justify-end">
              <div className="flex social-icons">
                <a href="mailto:info@studioredcam.com"><FaEnvelope className="text-white text-lg mr-3 hover:text-red-500" /></a> 
                <a href="https://www.facebook.com/redcam.studio22" target='blank' ><FaFacebookF className="text-white text-lg mr-3 hover:text-blue-500" /></a>
                <a href="https://twitter.com/redcam_studio" target='blank'><FaTwitter className="text-white text-lg mr-3 hover:text-blue-500" /></a>
                <a href="https://www.youtube.com/@redcamstudio22" target='blank' ><FaYoutube className="text-white text-lg mr-3 hover:text-red-500" /></a>
                <a href="https://www.instagram.com/redcam.studio/" target='blank' ><FaInstagram className="text-white text-lg mr-3 hover:text-pink-500" /></a>
                <a href="https://www.linkedin.com/in/redcam-studio-b9636929a/?originalSubdomain=pk" target='blank' ><FaLinkedinIn className="text-white text-lg mr-3 hover:text-blue-500" /></a>
                <a href="https://www.tiktok.com/@redcamstudio" target='blank' ><FaTiktok className="text-white text-lg hover:text-pink-500" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
