import React from 'react';
import { Link } from 'react-router-dom';
import backgroundImage from "../images/4.webp"
import Header from "./Header";
import Footer from "./Footer";
const Offers = () => {
  return (
    <>
    <Header/>

    <div className="min-h-screen bg-cover p-10 bg-center flex flex-col items-center justify-center" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <h1 className="text-3xl font-bold text-white font-spaceGrotesk mb-6 border-b border-red-500 align-middle">Wedding Packages</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Day 1 */}
          <div className="bg-white bg-opacity-75 rounded-lg p-8 transform transition duration-300 hover:scale-105">
            <h2 className="text-xl font-bold text-gray-800 mb-4">Day 1 Classic Package</h2>
            <ul className="text-gray-700">
              <li>Price: Rs 50,000</li>
              <li>Photographer: 1</li>
              <li>Cinematographer: 1</li>
              <li>Professional editing</li>
              <li>1 x Custom album 12x36</li>
            </ul>
            <div className="text-center mt-6">
              <Link
                to="/contact"
                className="bg-red-600  hover:bg-stone-800 text-white py-3 px-8 rounded-lg font-bold transition duration-300"
              >
                Book Now
              </Link>
            </div>
          </div>
          {/* Day 1 Silver Package */}
          <div className="bg-white bg-opacity-75 rounded-lg p-8 transform transition duration-300 hover:scale-105">
            <h2 className="text-xl font-bold text-gray-800 mb-4">Day 1 Silver Package</h2>
            <ul className="text-gray-700">
              <li>Price: Rs 70,000</li>
              <li>Photographer: 2</li>
              <li>Cinematographer: 2</li>
              <li>Professional editing</li>
              <li>1 x Custom album 12x36</li>
            </ul>
            <div className="text-center mt-6">
              <Link
                to="/contact"
                className="bg-red-600  hover:bg-stone-800 text-white py-3 px-8 rounded-lg font-bold transition duration-300"
              >
                Book Now
              </Link>
            </div>
          </div>
          {/* Day 1 Gold Package */}
          <div className="bg-white bg-opacity-75 rounded-lg p-8 transform transition duration-300 hover:scale-105">
            <h2 className="text-xl font-bold text-gray-800 mb-4">Day 1 Gold Package</h2>
            <ul className="text-gray-700">
              <li>Price: Rs 100,000</li>
              <li>Photographer: 3</li>
              <li>Cinematographer: 3</li>
              <li>Professional editing</li>
              <li>1 x Custom album 12x36</li>
            </ul>
            <div className="text-center mt-6">
              <Link
                to="/contact"
                className="bg-red-600  hover:bg-stone-800 text-white py-3 px-8 rounded-lg font-bold transition duration-300"
              >
                Book Now
              </Link>
            </div>
          </div>
          {/* Package Includes */}
          <div></div>
          <div className="mt-12 bg-white bg-opacity-75 text-center rounded-lg p-8 text-gray-700">
            <h2 className="text-xl font-bold mb-4">Package Includes:</h2>
            <ul className="text-gray-700">
              <li>All pictures in soft copies</li>
              <li>200 edited pictures in soft copies</li>
              <li>1 master video</li>
              <li>1 cinematic highlight video</li>
              <li>2 cinematic reels</li>
              <li>1 nine sheets custom album</li>
            </ul>
          </div>
          <div></div>

          {/* Day 2 */}
          <div className="bg-white bg-opacity-75 rounded-lg p-8 transform transition duration-300 hover:scale-105">
            <h2 className="text-xl font-bold text-gray-800 mb-4">Day 2 Classic Package</h2>
            <ul className="text-gray-700">
              <li>Price: Rs 90,000</li>
              <li>Photographer: 1</li>
              <li>Cinematographer: 1</li>
              <li>Professional editing</li>
              <li>1 x Custom album 12x36</li>
            </ul>
            <div className="text-center mt-6">
              <Link
                to="/contact"
                className="bg-red-600  hover:bg-stone-800 text-white py-3 px-8 rounded-lg font-bold transition duration-300"
              >
                Book Now
              </Link>
            </div>
          </div>
          {/* Day 2 Silver Package */}
          <div className="bg-white bg-opacity-75 rounded-lg p-8 transform transition duration-300 hover:scale-105">
            <h2 className="text-xl font-bold text-gray-800 mb-4">Day 2 Silver Package</h2>
            <ul className="text-gray-700">
              <li>Price: Rs 130,000</li>
              <li>Photographer: 2</li>
              <li>Cinematographer: 2</li>
              <li>Professional editing</li>
              <li>1 x Custom album 12x36</li>
            </ul>
            <div className="text-center mt-6">
              <Link
                to="/contact"
                className="bg-red-600  hover:bg-stone-800 text-white py-3 px-8 rounded-lg font-bold transition duration-300"
              >
                Book Now
              </Link>
            </div>
          </div>
          {/* Day 2 Gold Package */}
          <div className="bg-white bg-opacity-75 rounded-lg p-8 transform transition duration-300 hover:scale-105">
            <h2 className="text-xl font-bold text-gray-800 mb-4">Day 2 Gold Package</h2>
            <ul className="text-gray-700">
              <li>Price: Rs 160,000</li>
              <li>Photographer: 3</li>
              <li>Cinematographer: 3</li>
              <li>Professional editing</li>
              <li>1 x Custom album 12x36</li>
            </ul>
            <div className="text-center mt-6">
              <Link
                to="/contact"
                className="bg-red-600  hover:bg-stone-800 text-white py-3 px-8 rounded-lg font-bold transition duration-300"
              >
                Book Now
              </Link>
            </div>
          </div>
          <div></div>
          {/* Package Includes */}
          <div className="mt-12 bg-white bg-opacity-75 text-center rounded-lg p-8 text-gray-700">
            <h2 className="text-xl font-bold mb-4">Package Includes:</h2>
            <ul className="text-gray-700">
              <li>All pictures in soft copies</li>
              <li>300 edited pictures in soft copies</li>
              <li>1 master video</li>
              <li>2 cinematic highlight video</li>
              <li>5 cinematic reels</li>
              <li>2 nine sheets custom album</li>
            </ul>
          </div>
          
          <div></div>

          {/* Day 3 */}
          <div className="bg-white bg-opacity-75 rounded-lg p-8 transform transition duration-300 hover:scale-105">
            <h2 className="text-xl font-bold text-gray-800 mb-4">Day 3 Classic Package</h2>
            <ul className="text-gray-700">
              <li>Price: Rs 150,000</li>
              <li>Photographer: 1</li>
              <li>Cinematographer: 1</li>
              <li>Professional editing</li>
              <li>1 x Custom album 12x36</li>
            </ul>
            <div className="text-center mt-6">
              <Link
                to="/contact"
                className="bg-red-600  hover:bg-stone-800 text-white py-3 px-8 rounded-lg font-bold transition duration-300"
              >
                Book Now
              </Link>
            </div>
          </div>
          {/* Day 3 Silver Package */}
          <div className="bg-white bg-opacity-75 rounded-lg p-8 transform transition duration-300 hover:scale-105">
            <h2 className="text-xl font-bold text-gray-800 mb-4">Day 3 Silver Package</h2>
            <ul className="text-gray-700">
              <li>Price: Rs 200,000</li>
              <li>Photographer: 2</li>
              <li>Cinematographer: 2</li>
              <li>Professional editing</li>
              <li>1 x Custom album 12x36</li>
            </ul>
            <div className="text-center mt-6">
              <Link
                to="/contact"
                className="bg-red-600  hover:bg-stone-800 text-white py-3 px-8 rounded-lg font-bold transition duration-300"
              >
                Book Now
              </Link>
            </div>
          </div>
          {/* Day 3 Gold Package */}
          <div className="bg-white bg-opacity-75 rounded-lg p-8 transform transition duration-300 hover:scale-105">
            <h2 className="text-xl font-bold text-gray-800 mb-4">Day 3 Gold Package</h2>
            <ul className="text-gray-700">
              <li>Price: Rs 250,000</li>
              <li>Photographer: 3</li>
              <li>Cinematographer: 3</li>
              <li>Professional editing</li>
              <li>1 x Custom album 12x36</li>
            </ul>
            <div className="text-center mt-6">
              <Link
                to="/contact"
                className="bg-red-600  hover:bg-stone-800 text-white py-3 px-8 rounded-lg font-bold transition duration-300"
              >
                Book Now
              </Link>
            </div>
          </div>
          <div></div>
          {/* Package Includes */}
          <div className="mt-12 bg-white bg-opacity-75 text-center rounded-lg p-8 text-gray-700">
            <h2 className="text-xl font-bold mb-4">Package Includes:</h2>
            <ul className="text-gray-700">
              <li>All pictures in soft copies</li>
              <li>500 edited pictures in soft copies</li>
              <li>3 master video</li>
              <li>1 cinematic highlight video</li>
              <li>5 cinematic reels</li>
              <li>3 nine sheets custom album</li>
            </ul>
          </div>
          <div></div>
        </div>
      </div>
    <Footer/>
    </>
  
  );
};

export default Offers;
