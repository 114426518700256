import React from 'react'
import backgroundImage from '../images/bg-click.webp'; 
import { Link } from 'react-router-dom';

const HireUS = () => {
  return (
    <>
    <Link to="/services">
     <div
      className="relative bg-cover bg-center h-64 md:h-80 md:mt-10 lg:h-96 xl:h-104 hover:scale-110 duration-1000  flex items-center justify-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <h1 className='text-white font-bold text-3xl font-spaceGrotesk text-center'>Hire Us For Your Next Project</h1>
    </div>
    </Link>
    </>
  )
}

export default HireUS
