import React, { useState, useEffect } from 'react';
import { HashLoader } from 'react-spinners';

const withSpinner = (WrappedComponent) => {
  return (props) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      // Just to demonstrate that the HOC is setting the loading state properly.
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, []);

    return (
      <>
        {isLoading ? (
          <div className="min-h-screen flex justify-center items-center">
            <HashLoader color="#cd1a1a" size={100} />
          </div>
        ) : (
          <WrappedComponent {...props} setIsLoading={setIsLoading} />
        )}
      </>
    );
  };
};

export default withSpinner;
