import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AiTwotoneCloseCircle } from "react-icons/ai";
import Slider from "react-slick";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import backgroundImage from "../images/portfolio.webp";
import cardsData from './portfilio';
import Header from './Header';
import Footer from './Footer';
import { HashLoader } from 'react-spinners';
import gsap from 'gsap';

const PortfolioDetail = () => {
  const { id } = useParams();
  const [openPhoto, setOpenPhoto] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      const photo = cardsData.find((card) => card.id === parseInt(id));
      setSelectedPhoto(photo);
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [id]);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoading(false); // Set isLoading to false when the entire page is loaded
    };

    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  useEffect(() => {
    const images = Object.values(selectedPhoto?.ImageDetail || {});
    let loadedCount = 0;

    const loadImage = () => {
      images.forEach(image => {
        const img = new Image();
        img.onload = () => {
          loadedCount++;
          if (loadedCount === images.length) {
            setImagesLoaded(true);
          }
        };
        img.src = image;
      });
    };

    if (selectedPhoto) {
      loadImage();
    }

    return () => {
      setImagesLoaded(false);
    };
  }, [selectedPhoto]);

  const openModal = (index) => {
    setInitialSlide(index);
    setOpenPhoto(true);
  };

  const closeModal = () => {
    setOpenPhoto(null);
  };

  const CustomPrevArrow = ({ onClick }) => (
    <div
      className="absolute top-1/2 left-0 transform -translate-y-1/2 w-10 h-10 bg-black bg-opacity-50 text-white flex justify-center items-center cursor-pointer z-10"
      onClick={onClick}
    >
      <IoIosArrowBack />
    </div>
  );

  const CustomNextArrow = ({ onClick }) => (
    <div
      className="absolute top-1/2 right-0 transform -translate-y-1/2 w-10 h-10 bg-black bg-opacity-50 text-white flex justify-center items-center cursor-pointer z-10"
      onClick={onClick}
    >
      <IoIosArrowForward />
    </div>
  );

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 400,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "ease-in-out",
    pauseonHover: false,
    pauseonFocus: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    initialSlide: initialSlide // Add this line
  };

  useEffect(()=>{
    gsap.fromTo('.heading', {
      x: -100,
      opacity: 0,
    }, {
      x: 0,
      opacity: 1,
      duration: 2,
      delay:1
    });
  },[])

  return (
    <>
      <Header />

      {isLoading ? (
        <div className="min-h-screen flex justify-center items-center">
          <HashLoader
            color="#cd1a1a"
            size={100}
          />
        </div>
      ) : (
        selectedPhoto && (
          <div className="min-h-screen bg-cover p-10 bg-center flex flex-col items-center justify-center" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <h1 className="text text:xs md:text-3xl font-bold text-white font-spaceGrotesk mt-10 mb-6 underline decoration-red-600 text-center">{selectedPhoto.title}</h1>
            <p className="text text-white text-xl font-spaceGrotesk mb-4">{selectedPhoto.description}</p>
            <div className="grid lg:grid-cols-6 grid-cols-3 items-center">
              {Object.values(selectedPhoto.ImageDetail).map((image, index) => (
                <img
                  key={index}
                  src={image}
                  className="w-24 md:w-48 h-auto mr-2 md:mr-4 mb-4 rounded-lg shadow-md hover:opacity-75 transition-opacity duration-300 max-w-full"
                  onClick={() => openModal(index)}
                  alt={`Img ${index + 1}`}
                />
              ))}
            </div>
          </div>
        )
      )}

      {openPhoto && imagesLoaded && (
        <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-75" onClick={closeModal}>
          <div className="max-w-screen-xl w-full p-4 bg-black rounded-lg overflow-hidden" onClick={(e) => e.stopPropagation()}>

            <Slider {...settings}>
              {Object.values(selectedPhoto.ImageDetail).map((image, index) => (
                <div key={index} className='relative flex'>
                  <img
                    src={image}
                    className='w-full h-full object-contain mt-10 max-w-full max-h-full'
                    alt={`Img ${index + 1}`}
                    style={{ maxHeight: '80vh', maxWidth: '80vw' }}
                  />
                </div>
              ))}
            </Slider>

            <button onClick={closeModal} className="absolute top-4 right-4 text-4xl text-white focus:outline-none">
              <AiTwotoneCloseCircle />
            </button>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default PortfolioDetail;
