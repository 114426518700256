import React, { useState} from 'react';
import { Link } from 'react-router-dom';
import redcamLogo from '../images/logo.PNG';
import { Bars3BottomRightIcon, XMarkIcon } from '@heroicons/react/24/solid'

const Header = () => {
    const Links = [
        { name: "HOME", link: "/" },
        { name: "ABOUT", link: "/About" },
        { name: "SERVICES", link: "/services" },
        { name: "GALLERY", link: "/gallery" },
        { name: "CONTACT", link: "/contact" },
    ];
    const [open, setOpen] = useState(false);


    return (
        <div className={`shadow-md w-full fixed bg-black top-0  left-0 z-50 transition-all duration-300`}>
            <div className='md:flex items-center justify-between py-4 md:px-10 px-7'>
                <div className='font-bold text-2xl cursor-pointer flex items-center gap-1'>
                    <Link to="/">
                        <img src={redcamLogo} alt='Redcam Studio Logo' className='md:h-8 h-6 z-50 ' />
                    </Link>
                </div>
                <div onClick={() => setOpen(!open)} className='absolute right-8 top-6 cursor-pointer text-white md:hidden w-7 h-7'>
                    {
                        open ? <XMarkIcon /> : <Bars3BottomRightIcon />
                    }
                </div>
                <ul className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${open ? 'top-12' : 'top-[-490px]'} ${open && 'bg-black'}`}>
                    {
                        Links.map((link, index) => (
                            <li key={index} className='md:ml-8 md:my-0 my-7 font-semibold '>
                                <a href={link.link} className='text-white cursor-pointer font-spaceGrotesk hover:text-gray-300 hover:underline decoration-red-500' >{link.name}</a>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    );
};
export default Header;
