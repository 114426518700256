import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import studio from "../images/stu.webp";
import { ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

const AboutHome = () => {
  const textRefs = useRef([]);
  const imageRef = useRef(null);

  useEffect(() => {
    textRefs.current.forEach((ref, index) => {
      gsap.fromTo(ref,
        {
          x: 100,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
          ease: "power1.inOut",
          duration: 2,
          scrollTrigger: {
            trigger: ref,
            start: "top 85%",
            end: "top 20%",
            toggleActions: "play none none none ",
          },
          delay: index * 0.5,
        }
      );
    });

    if (imageRef.current) {
      gsap.fromTo(imageRef.current,
        {
          x: -200,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
          ease: "power1.inOut",
          duration: 2,
          scrollTrigger: {
            trigger: imageRef.current,
            start: "top 85%",
            end: "top 20%",
            toggleActions: "play none none none",
          },
        }
      );
    }
  }, []);

  return (
    <div className="min-h-screen bg-cover flex flex-col items-center justify-center">
      <div className="max-w-full w-full">
        <div className="w-full flex flex-col md:flex-row-reverse">
          <div className="w-full md:w-1/2 bg-black text-white p-8 md:p-16">
            <h1
              ref={(el) => (textRefs.current[0] = el)}
              className="text-3xl md:text-5xl font-bold font-spaceGrotesk my-4 underline decoration-red-600"
            >
              OUR STUDIO
            </h1>
            <p
              ref={(el) => (textRefs.current[1] = el)}
              className="text-sm md:text-2xl font-spaceGrotesk"
            >
              Our studio is fully equipped with state-of-the-art equipment to ensure that your filming experience is smooth and successful.
              We have a wide range of equipment available, including cameras, lighting, sound equipment, and editing tools, all designed to help you create high-quality films that meet your creative vision.
              <p ref={(el) => (textRefs.current[2] = el)} className="my-10">
                Our studio has everything you need to bring your ideas to life on the big screen.
              </p>
            </p>
          </div>
          <div className="w-full md:w-1/2 relative">
            <img
              ref={imageRef}
              className="about_image w-full h-auto"
              src={studio}
              alt="Studio"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutHome;
