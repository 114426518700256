import React, { useEffect,useRef } from 'react'
import backgroundImage from "../images/bg-about.webp"
import image from "../images/SATISH.webp"
import studio from "../images/studio.webp"
import Header from './Header'
import Footer from './Footer'
import gsap from 'gsap'


const SatishMalani = () => {
  const textRefs = useRef([]);
  const imageRef = useRef([null]);

  useEffect(() => {
    gsap.fromTo('.heading',{
      y: -100,
      opacity: 0,
    },
  {
    y: 0,
      opacity: 1,
      duration: 2,
  })
    textRefs.current.forEach((ref, index) => {
      gsap.fromTo(ref,
        {
          x: -100,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
          ease: "power1.inOut",
          duration: 1,
          stagger:0.5,
          scrollTrigger: {
            trigger: ref,
            start: "top 85%",
            end: "top 20%",
            toggleActions: "play none none none ",
          },
          delay: index * 0.1,
        }
      );
    });

    if (imageRef.current) {
      gsap.fromTo(imageRef.current,
        {
          x: 200,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
          ease: "power1.inOut",
          duration: 1,
          scrollTrigger: {
            trigger: imageRef.current,
            start: "top 85%",
            end: "top 20%",
            toggleActions: "play none none none",
          },
          delay:  0.3,
        }
      );
    }
  }, []);
  return (
    <>
      <Header />
      <div className="min-h-screen bg-cover p-10 bg-center flex flex-col items-center justify-center" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <h1  className="heading text-3xl font-bold text-white font-spaceGrotesk mt-10 mb-6 border-b border-red-500 align-middle">ABOUT</h1>
        <div className='max-w-4xl '>
          <div className='w-[100%] p-4 flex justify-between items-center flex-col md:flex-row'>
            <div className='w-[50%]'>
              <h1 ref={(el)=>(textRefs.current[0]=el)} className='text-2xl font-bold text-white font-spaceGrotesk my-4 underline  decoration-red-500'>OWNER / CEO</h1>
              <p ref={(el)=>(textRefs.current[1]=el)} className='text-sm md:text-xl text-white mb-16 font-spaceGrotesk' >
                RedCam Studio is run by <span className='text-red-600'>Satish Malani</span>, a professional photographer, videographer, editor and a Media Science graduate with experience of 9 years and counting.
              </p>
            </div>
            <div className=''>
              <img ref={(el)=>(imageRef.current[0]=el)} className="h-80 w-80" src={image} alt="satishMalani" />
            </div>
          </div>

          <div className='w-[100%] p-2 flex justify-between  items-center flex-col md:flex-row py-8 md:py-0'>
            <div className='w-[50%] mb-16'>
              <h1 ref={(el)=>(textRefs.current[3]=el)} className='text-2xl font-bold text-white font-spaceGrotesk my-4 underline  decoration-red-500' >OUR STUDIO</h1>
              <p ref={(el)=>(textRefs.current[4]=el)} className='text-xs  md:text-xl font-spaceGrotesk text-white' >
                RedCam Studio is the go-to
                place for you for any of your
                photography or videography
                related requirements.
                We are Karachi based Media
                House, providing our services
                all across Pakistan with best
                available equipment,
                experienced and skilled team,
                and never compromising on
                quality attitude. Our studio is equipped with the latest photography, videography, and sound equipment, and we utilize cutting-edge editing software to enhance the quality of our images and videos. We guarantee that you'll love the results of our work.
              </p>
            </div>
            <div className=''>
              <img ref={(el)=>(imageRef.current[1]=el)} className="h-80 w-80" src={studio} alt="satishMalani" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>

  )
}

export default SatishMalani
