import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

const CounterItem = React.forwardRef(({ number, label }, ref) => (
  <div ref={ref} className="col-lg-3 col-md-6 col-sm-6 mb-4 text-center ">
    <div className="p-10" style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }}>
      <h3 className="text-4xl font-bold text-white mb-2">{number}</h3>
      <span className="text-sm text-white">{label}</span>
    </div>
  </div>
));

const CounterSection = () => {
  const boxRefs = useRef([]);

  useEffect(() => {
    boxRefs.current.forEach((ref, index) => {
      gsap.fromTo(ref,
        {
          y: -100,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          ease: "power1.inOut",
          duration: 3,
          stagger:0.5,
          scrollTrigger: {
            trigger: ref,
            start: "top 85%",
            end: "top 20%",
            toggleActions: "play none none none ",
          },
          delay: index * 0.5,
        }
      );
    });
  },[]);

  return (
    <div className="py-10">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 font-spaceGrotesk gap-4">
          <CounterItem number={2240} label="Hours of Works" ref={(el) => (boxRefs.current[0] = el)} />
          <CounterItem number={115} label="Projects Done" ref={(el) => (boxRefs.current[1] = el)} />
          <CounterItem number={90} label="Satisfied Customers" ref={(el) => (boxRefs.current[2] = el)} />
          <CounterItem number={10} label="Awards Winning" ref={(el) => (boxRefs.current[3] = el)} />
        </div>
      </div>
    </div>
  );
};

export default CounterSection;
