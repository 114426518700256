import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import ContactForm from "./components/ContactForm";
import About from "./components/About"
import Offers from "./components/Offers";
import Services from "./components/Services";
import Gallery from "./components/Gallery";
import PortfolioDetail from "./components/GalleryDetails";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About/>} />
        <Route path="/gallery" element={<Gallery/>} />
        <Route path="//PortfolioDetail/:id" element={<PortfolioDetail/>} />
        <Route path="/services" element={<Services/>} />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/Offers" element={<Offers />} />
      </Routes>
    </Router>
  );
}

export default App;
