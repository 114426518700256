import React from 'react'
import Header from "./Header";
import Footer from "./Footer";
import WhatsAppButton from "./WhatsAppButton";
import ContactSection from './ContactForm';
import HireUs from "./HireUS"
import Hero from "./hero/Hero"
import backgroundImage from "../images/portfolio.webp"
import GalleryHome from './GalleryHome';
import AboutHome from './AboutHome';
import CounterItem from './CounterItem';
import withSpinner from './withSpinner';


const Home = () => {
  
  return (
    <div className="relative bg-cover bg-center"
    style={{ backgroundImage: `url(${backgroundImage})` }}>
        <Header />
        <Hero/>
        <GalleryHome/>
        <AboutHome/>
        <CounterItem/>
        <HireUs/>
        <ContactSection
        showHeader={false}
        showFooter={false}
        />
        <WhatsAppButton />
        <Footer />
    </div>
  )
}

export default withSpinner(Home);
